export default {
  ok: {
    en: 'Okay',
  },
  cancel: {
    en: 'Cancel',
  },
  close: {
    en: 'Close',
  },
  continue: {
    en: 'Continue',
  },
  connect: {
    en: 'Connect wallet',
  },
  processing: {
    en: 'Processing',
  },
  preparing: {
    en: 'Preparing',
  },
  makeDeposit: {
    en: 'Make a deposit',
  },
  openCase: {
    en: 'Open case',
  },
  signMessage: {
    en: 'Sign message',
  },
  switchChain: {
    en: 'Switch chain',
  },
  viewTransaction: {
    en: 'View Transaction In Explorer',
  },
}
