import type { MouseEventHandler } from 'react'
import clsx from 'clsx'
import { type HrefProps, Href } from 'components/navigation'

export type ButtonStyling = 'main' | 'second' | 'third'
export type ButtonSize = 's' | 'big'

export type ButtonBaseProps = Omit<HrefProps, 'title' | 'style' | 'message' | 'onClick'> & {
  className?: string
  disabled?: boolean
  loading?: boolean
  type?: HTMLButtonElement['type']
  size: ButtonSize | null
  styling: ButtonStyling | null
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | null
}

const classNameBySize: Record<ButtonSize, string> = {
  s: clsx('btn-size-s'),
  big: clsx('btn-size-big'),
}

const classNameByStyle: Record<ButtonStyling, string> = {
  main: clsx('btn-main'),
  second: clsx('btn-second'),
  third: clsx('btn-third'),
}

const ButtonBase: React.FC<ButtonBaseProps> = (props) => {
  const {
    children,
    className,
    styling,
    size,
    type = 'button',
    loading,
    disabled,
    onClick,
    ...rest
  } = props

  const isLink = Boolean(rest.to || rest.toTab || rest.href || rest.mailto)

  const rootClassName = clsx(className, {
    'pointer-events-none': loading || disabled,
    [classNameBySize[size!]]: size,
    [classNameByStyle[styling!]]: styling,
  })

  const handleClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (event) => {
    if (disabled || loading) {
      event.preventDefault()

      return
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  if (isLink) {
    return (
      <Href
        className={rootClassName}
        role="button"
        onClick={handleClick}
        aria-disabled={disabled || loading}
        aria-busy={loading}
        {...rest}
      >
        {children}
      </Href>
    )
  }

  return (
    <button
      className={rootClassName}
      type={type}
      disabled={disabled || loading}
      aria-busy={loading}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export default ButtonBase
